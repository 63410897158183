import Head from 'next/head';
import { useRouter } from "next/router";
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Footer from './Footer.js';
import HeaderBody from './Header';
import { isIOS, isMobile } from 'react-device-detect';
import MetaSeo from './MetaSeo';
import React from 'react';
// import isMobile from 'ismobilejs/types/isMobile.js';



function Layout({ children, webViewMobile }) {
    const dispatch = useDispatch();
    const router = useRouter();
    const [showMenu, setShowMenu] = useState(false);
    const [show_header, setShowHeader] = useState('');
    const [activeHead, setActiveHead] = useState(true);
    const [position, setPosition] = useState(0);
    const positionY = React.useRef(null);
    const changeShowMenu = () => {
        setShowMenu(!showMenu);
    };
    useEffect(() => {
        if (isMobile) {
            if (showMenu) {
                lockScroll();
            }
            else {
                unlockScroll();
            }
        }
    }, [showMenu]);
    const lockScroll = React.useCallback(() => {
        positionY.current = window.scrollY;
        document.body.style.overflowY = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `${positionY.current}px`;
    }, []);


    const unlockScroll = React.useCallback(() => {
        document.body.style.overflowY = '';
        document.body.style.position = '';
        document.body.style.top = '';
        scrollTo(0, positionY.current);

    }, []);
    const dataSeo = {
        url: 'https://tcom.vn',
        type: 'website',
        title: "TCOM",
        description: 'Tcom tech for life',
        domain: 'tcom.vn',
        image: 'https://tcom.vn/assets/images/about/gallery-2.png',
        card: 'summary_large_image',
        keywords: 'Artificial Intelligence, Blockchain, AR/VR, Livestreaming, Tuyển dụng IT, Kỹ sư phần mềm, NodeJS, iOS, Android, Python, BrSE, BA, Comtor'
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return (() => window.removeEventListener('scroll', onScroll));
    });

    const onScroll = () => {
        let moving = window.pageYOffset;
        if (moving <= 0) {
            setActiveHead(true);
        }
        else {
            setActiveHead(position > moving);
            setPosition(moving);
        }

    };

    return (
        <>
            <Head>
                <meta charSet='UTF-8' />
                <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <link rel='preconnect' href='https://fonts.googleapis.com' />
                <link rel='preconnect' href='https://fonts.gstatic.com' />
                <link rel='apple-touch-icon' sizes='57x57' href='/apple-icon-57x57.png' />
                <link rel='apple-touch-icon' sizes='60x60' href='/apple-icon-60x60.png' />
                <link rel='apple-touch-icon' sizes='72x72' href='/apple-icon-72x72.png' />
                <link rel='apple-touch-icon' sizes='76x76' href='/apple-icon-76x76.png' />
                <link rel='apple-touch-icon' sizes='114x114' href='/apple-icon-114x114.png' />
                <link rel='apple-touch-icon' sizes='120x120' href='/apple-icon-120x120.png' />
                <link rel='apple-touch-icon' sizes='144x144' href='/apple-icon-144x144.png' />
                <link rel='apple-touch-icon' sizes='152x152' href='/apple-icon-152x152.png' />
                <link rel='apple-touch-icon' sizes='180x180' href='/apple-icon-180x180.png' />
                <link rel='icon' type='image/png' sizes='192x192' href='/android-icon-192x192.png' />
                <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
                <link rel='icon' type='image/png' sizes='96x96' href='/favicon-96x96.png' />
                <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
                <link rel='manifest' href='/manifest.json' />

                <link href='https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap' rel='stylesheet' />
                <title>Cơ hội nghề nghiệp | TCOM Corporation</title>
            </Head>
            <div className={showMenu ? 'dt-body on-nav' : ''}>
                {activeHead && <HeaderBody setShowMenu={changeShowMenu} />}
                {children}
            </div>
            <Footer />

        </>


    );
}
export default Layout;
Layout.propTypes = {
    children: PropTypes.any
};

Layout.defaultProps = {
    children: null
};