import Head from 'next/head';
export default function MetaSeo(props) {
    let { url, type, title, description, image, domain, card, keywords } = props;
    if (!url) {
        url = 'https://tcom.vn';
    }
    if (!type) {
        type = 'website';
    }
    if (!title) {
        title = 'Cơ hội nghề nghiệp | TCOM Corporation';
    }
    if (!description) {
        description = 'TCOM là công ty công nghệ hàng đầu tại Việt Nam. Với chúng tôi, nguồn nhân lực là giá trị cốt lõi và là nền tảng vững chắc để không ngừng tạo nên những giá trị thiết thực cho khách hàng và xã hội. Hãy tham gia ngay để khám phá và phát triển tài năng của bạn.';
    }
    if (!image) {
        image = 'https://tcom.vn/assets/images/tcom.jpg';
    }
    if (!domain) {
        domain = 'tcom.vn';
    }
    if (!card) {
        card = 'summary_large_image';
    }
    if (!keywords) {
        keywords = 'Artificial Intelligence, Blockchain, AR/VR, Livestreaming, Tuyển dụng IT, Kỹ sư phần mềm, NodeJS, iOS, Android, Python, BrSE, BA, Comtor';
    }
    return (
        <>
            <Head>
                <meta property='title' content={title} />
                <meta property='description' content={description} />
                <meta property='keywords' content={keywords} />
                <meta name='news_keywords' content={keywords} />

                <meta property='og:url' content={url} />
                <meta property='og:type' content={type} />
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='og:image' content={image} />


                <meta name='twitter:card' content={card} />
                <meta property='twitter:domain' content={domain} />
                <meta property='twitter:url' content={url} />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} />
                <meta name='twitter:image' content={image} />
            </Head>
        </>
    );
}
