import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';
import job from './job';
const _reducers = combineReducers({
  job,
});

function reducers(state, action) {
  if (action.type === HYDRATE) {
    return { ...state, ...action.payload };
  }
  return _reducers(state, action);
}

export default reducers;