import { LOAD_LIST_JOB } from '@/constants/ActionTypes';

  const initialState = {
    listJob: null,
};
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_LIST_JOB:
            return {
                ...state,
                listJob: action.payload
            };
        default:
            return { ...state };
    }
};
export default userReducer;